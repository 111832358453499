import { HTTPError } from 'ky'
import { z } from 'zod'
import { tryto } from '~/lib/utils/tryto'

/** Ensures the error was thrown by ky */
export function isHttpError(response: unknown): response is HTTPError {
  return response instanceof HTTPError
}

export enum serverErrorType {
  /** Generic server error: usually it has to be reported as a bug */
  httpError = 'HTTP_ERROR',
  /** 400 Bad Request: Validation error, should be proccessed */
  inputValidationError = 'INPUT_VALIDATION_ERROR',
  notFound = 'NOT_FOUND_ERROR',
  orgNotFound = 'ORGANIZATION_NOT_FOUND_ERROR',
  projectNotFound = 'PROJECT_NOT_FOUND_ERROR',
  almInstanceNotFound = 'ALM_INSTANCE_NOT_FOUND_ERROR',
  issueNotFound = 'ISSUE_INSTANCE_NOT_FOUND_ERROR',
  /** Upstream error: Parse recursively to get the actual error */
  ssoError = 'SSO_API_ERROR',
  userNotFound = 'SIGN_IN_FAILED_USER_NOT_FOUND',
  passwordMismatch = 'SIGN_IN_FAILED_PASSWORD_MISMATCH',
  memberAlreadyInvited = 'MEMBER_ALREADY_INVITED',
  entityDuplicationErr = 'ENTITY_DUPLICATION_ERR',
  databaseError = 'DATABASE_ERROR',
  signInNoTeamAccess = 'SIGN_IN_FAILED_NO_TEEM_ACCESS',
  pwdChangeFailedOldPasswordMismatch = 'PWD_CHANGE_FAILED_OLD_PASSWORD_MISMATCH',
  linkIsExpired = 'INVITATION_LINK_WAS_DEACTIVATED',
  alreadyMember = 'USER_IS_ALREADY_MEMBER_ERROR',
}

export type BaseServerError = z.infer<typeof baseServerErrorSchema>
const baseServerErrorSchema = z.object({ errors: z.array(z.any()) })
export function isServerError(error: unknown): error is BaseServerError {
  return baseServerErrorSchema.safeParse(error).success
}

const validationErrorSchema = z.object({
  errorMessage: z.string(),
  errorType: z.literal(serverErrorType.inputValidationError),
  fieldName: z.string(),
})

export function getValidationErrors(serverError: BaseServerError) {
  let hasErrors = false
  const validationErrors: Record<string, string> = {}
  for (const error of serverError.errors) {
    const [validationError] = tryto.run(() =>
      validationErrorSchema.parse(error),
    )
    if (validationError) {
      hasErrors = true
      validationErrors[validationError.fieldName] = validationError.errorMessage
    }
  }
  return hasErrors ? validationErrors : null
}

export function getGenericError(serverError: BaseServerError) {
  const [error] = serverError.errors
  if (isSsoError(error)) {
    return getGenericError(error.upstreamError.serviceResponse)
  }
  return error as GenericError
}

export type GenericError = {
  errorClass: string
  errorMessage?: string
  errorType:
    | Exclude<
        serverErrorType,
        serverErrorType.inputValidationError | serverErrorType.ssoError
      >
    | (string & {})
}

export function forceErrorToBadRequest(
  error: BaseServerError,
): ServerErrorBadRequest {
  return error
}

type ServerErrorBadRequest = {
  errors: Array<ServerValidationError | ServerAnyError>
}

type ServerAnyError =
  | ServerSsoError
  | ServerGenericHttpError
  | ServerUnknownError

export type ServerValidationError = {
  errorClass: string
  errorMessage: string
  errorType: serverErrorType.inputValidationError
  fieldName: string
}

export type ServerSsoError = {
  errorClass: string
  errorMessage: string
  errorType: serverErrorType.ssoError
  upstreamError: {
    statusCode: number
    serviceResponse: {
      errors: any[]
    }
  }
}

export type ServerGenericHttpError = {
  errorClass: string
  errorMessage: string
  errorType: serverErrorType.httpError
}

export type ServerUnknownError = {
  errorClass: string
  errorType?: string
}

export function isSsoError(error: any): error is ServerSsoError {
  return error?.errorType === serverErrorType.ssoError
}

export function processUpstreamError(error: ServerSsoError) {
  const upstreamError = error.upstreamError.serviceResponse.errors[0]
  if (upstreamError) {
    if (isSsoError(upstreamError)) {
      return processUpstreamError(upstreamError)
    }
    return upstreamError
  } else {
    return null
  }
}

export type UnpackedHttpError<T = any> = {
  data: T
  error: {
    req: Request
    res: Response
    reqHeaders: Record<string, string>
    resHeaders: Record<string, string>
  }
  message: string
  status: number
  url: string
}

export async function unpackHttpError<T = any>(error: HTTPError) {
  const { request, response } = error

  const [json] = await tryto.promise(response.clone().json())

  const unpacked: UnpackedHttpError<T> = {
    data: json,
    error: {
      req: request.clone(),
      reqHeaders: Object.fromEntries(request.headers.entries()),
      res: response.clone(),
      resHeaders: Object.fromEntries(response.headers.entries()),
    },
    message: error.message,
    status: response.status,
    url: request.url,
  }

  return unpacked
}
